<template>
    <div
        class="the-cart-header-dropdown"
        :class="{'the-cart-header-dropdown--showed':  isHeaderCartHovered }"
        ref="dropdown"
    >
        <div v-if="cart.products.length">
            <div class="the-cart-header-dropdown__products-area">
                <div
                    v-for="(product, key) in cart.products"
                    :key="key"
                    class="the-cart-header-dropdown__row"
                    :class="{
                        'the-cart-header-dropdown__row--deleted': product.attributes.deleted
                    }"
                >
                    <div
                        class="the-cart-header-dropdown__item-number"
                        :class="{
                            'opacity-0': product.attributes.deleted
                        }"
                    >
                        {{ key + 1 }}
                    </div>
                    <div class="the-cart-header-dropdown__img-layout">
                        <a :href="product.links.self" class="the-cart-header-dropdown__img-link">
                            <img :src="product.links.image" type="image/jpg" alt="" class="w-20 h-20 object-contain" />
                        </a>
                    </div>
                    <div class="the-cart-header-dropdown__info">
                        <div
                            v-if="!product.attributes.deleted"
                            class="the-cart-header-dropdown__product-info"
                        >
                            <a :href="product.links.self" class="the-cart-header-dropdown__link the-cart-header-dropdown__link--section">
                                {{product.attributes.categoryName}}
                            </a>
                            <a :href="product.links.self" class="the-cart-header-dropdown__link the-cart-header-dropdown__link--name">
                                {{product.attributes.name}}
                            </a>
                            <div v-if="product.attributes.availableForOrder" class="the-cart-header-dropdown__price-layout">
                                <div class="the-cart-header-dropdown__new-price">
                                    {{product.attributes.cartQuantity }} x {{ product.attributes.price }}
                                </div>
                                <div class="the-cart-header-dropdown__old-price line-through" v-if="product.attributes.oldPrice">
                                    {{ product.attributes.oldPrice }}
                                </div>
                            </div>
                            <div v-else class="text-pink-antique">Недоступно</div>
                        </div>
                        <div
                            v-else
                            class="the-cart-header-dropdown__delete-info"
                        >
                            Вы удалили этот товар,<br/>
                            хотите его востановить?
                        </div>
                    </div>
                    <div class="the-cart-header-dropdown__control">
                        <div
                            v-if="!product.attributes.deleted"
                            class="the-cart-header-dropdown__delete"
                            @click="deleteProduct(product)"
                        >
                            <SvgIcon class="the-cart-header-dropdown__delete-svg" name="icon-close-thick" />
                        </div>
                        <div
                            v-else
                            class="the-cart-header-dropdown__return"
                            @click="restoreProduct(product)"
                        >
                            <SvgIcon class="the-cart-header-dropdown__return-svg" name="icon-restore" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="the-cart-header-dropdown__line"></div>
            <div class="the-cart-header-dropdown__price-area" v-if="cart.products.length && cart.totals && cart.products.length !== deletedProducts.length">
                <div class="the-cart-header-dropdown__price-layout flex justify-center">
                    <span class="the-cart-header-dropdown__label">Итого:</span>
                    <span
                        class="loading-highlighter the-cart-header-dropdown__new-price"
                        :class="{
                            'loading-highlighter--skeleton': cart.isLoading
                        }"
                    >
                        {{ cart.totals.attributes.products }}
                    </span>
                    <span
                        v-if="cart.totals.attributes.subtotalRaw"
                        class="loading-highlighter the-cart-header-dropdown__old-price line-through"
                        :class="{
                            'loading-highlighter--skeleton': cart.isLoading
                        }"
                    >
                        {{ cart.totals.attributes.productsWODiscount }}
                    </span>
                </div>
            </div>
            <div class="the-cart-header-dropdown__buttons-area" v-if="cart.products.length && cart.products.length !== deletedProducts.length">
                <a
                    href="/cart"
                    class="loading-highlighter button button--black !px-[19px]"
                    :class="{
                        'loading-highlighter--disabled': cart.isLoading
                    }"
                >
                    Оформить
                </a>
                <a
                    href="/cart?oneClickMode"
                    class="loading-highlighter button !px-[19px]"
                    :class="{
                        'loading-highlighter--disabled': cart.isLoading
                    }"
                >
                    Купить в 1 клик
                </a>
            </div>
        </div>
        <div v-else class="the-cart-header-dropdown__empty-text">
            В вашей корзине пока нет товаров
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import cartStore            from "~/store/cart"
import { CartProduct }      from "~/interfaces/cart/cart"
import { AddedProduct }     from "~/interfaces/cart/added-product"
import { priceFormatter }   from "~/helpers/price-formatter"
import SvgIcon              from "~vue/misc/SvgIcon.vue"

export default defineComponent({
    name: "TheCartHeaderDropdown",
    components: { SvgIcon },
    data() {
        return {
            isHeaderCartHovered: false,
        }
    },
    setup() {
        const { cart } = cartStore.refs

        const { deleteProductQuantity, addToCart } = cartStore.actions

        return {
            cart,
            deleteProductQuantity,
            addToCart,
        }
    },
    mounted() {
        this.registerListeners()
    },
    computed: {
        deletedProducts(): CartProduct[] {
            return this.cart.products.filter(el => el.attributes.deleted)
        }
    },
    methods: {
        registerListeners() {
            const headerEntry = document.querySelector<HTMLElement>('.header__user-links-item--cart')
            const dropdownElement = this.$refs.dropdown as HTMLElement

            let timer = 0

            if (!headerEntry || !dropdownElement) return void 0

            headerEntry.addEventListener('mouseenter', () => {
                clearTimeout(timer)
                timer = window.setTimeout(() => {
                    this.isHeaderCartHovered = true
                }, 500)
            })
            headerEntry.addEventListener('mouseleave', () => {
                clearTimeout(timer)
                timer = window.setTimeout(() => {
                    this.isHeaderCartHovered = false
                }, 750)
            })
            dropdownElement.addEventListener('mouseenter', () => {
                clearTimeout(timer)

                this.isHeaderCartHovered = true
            })
            dropdownElement.addEventListener('mouseleave', () => {
                clearTimeout(timer)
                timer = window.setTimeout(() => {
                    this.isHeaderCartHovered = false
                }, 750)
            })
        },
        priceFormat (price: number):string {
            return priceFormatter(price, this.cart.cartData?.attributes.currency)
        },
        async deleteProduct(productToDelete: CartProduct) {
            if (this.cart.isLoading) return void 0

            productToDelete.attributes.deleted = true

            document.dispatchEvent(new CustomEvent('productDeleteFromHeader'))

            await this.deleteProductQuantity(
                [productToDelete.id]
            )
        },
        async restoreProduct(productToRestore: CartProduct) {
            if (this.cart.isLoading) return void 0

            productToRestore.attributes.deleted = false

            const addedProduct: AddedProduct = {
                id: productToRestore.id,
                quantity: productToRestore.attributes.cartQuantity
            }

            document.dispatchEvent(new CustomEvent('productRestoreFromHeader'))

            await this.addToCart([addedProduct], false, true)
        },
    },
})
</script>
